
const Log = {
  /** no debug at all */
  LOG_LEVEL_NONE: 0,

  /** show even the debug messages */
  LOG_LEVEL_DEBUG: 1,

  /** show all up to (and including) info
     *Setting the log level at info level will slow your browser a lot....so use it carefully
     * */
  LOG_LEVEL_INFO: 2,

  /** show only errors */
  LOG_LEVEL_ERROR: 3,

  /** It will keep the log level (anything above this level will be printed) */
  level: 2,

  /**
    * The less important of all messages
    * @param {String} message - the message to be logged
    * */
  debug(...message) {
    if (typeof console !== 'undefined') {
      if (this.level <= this.LOG_LEVEL_DEBUG) {
        // in FF is debug
        if (typeof console.debug === 'function') {
          console.debug(...message);
        } else { // TODO: in IE is log
          //                    console.info(...message);
        }
      }
    }
  },


  /**
    * The commonly used log message
    * @param {String} message - the message to be logged
    * */
  info(...message) {
    if (typeof console !== 'undefined') {
      if (this.level <= this.LOG_LEVEL_INFO) {
        console.info(...message);
      }
    }
  },

  /**
    * The worse kind of message. Usually a crash
    * @param {String} message - the message to be logged
    * */
  error(...message) {
    if (typeof console !== 'undefined') {
      if (this.level <= this.LOG_LEVEL_ERROR) {
        console.error(...message);
      }
    }
  },

  /**
     *Start grouping the log messages
     *@param {String} title - the title of the group
     *@see <a href="http://getfirebug.com/logging">http://getfirebug.com/logging</a>
     * */
  group(title) {
    if (this.level <= this.LOG_LEVEL_INFO) { // ignore group if level not debug or info
      if (typeof console !== 'undefined') {
        /** If we do not test for group() function you will get an error in Opera
                 *as Opera has it's own console...which does not have a group() function */
        if (typeof console.group === 'function') {
          console.group(title);
        }
      }
    }
  },

  /** Ends current message grouping */
  groupEnd() {
    if (this.level <= this.LOG_LEVEL_INFO) { // ignore group if level not debug or info
      if (typeof console !== 'undefined') {
        /** If we do not test for groupEnd() function you will get an error in Opera
                 *as Opera has it's own console...which does not have a group() function */
        if (typeof console.groupEnd === 'function') {
          console.groupEnd();
        }
      }
    }
  },

};

/* Set the log level */
// Log.level = Log.LOG_LEVEL_DEBUG;
// Log.level = Log.LOG_LEVEL_ERROR;

window.Log = Log;

export const setupConsoleWithConfig = () => {
  const newConsole = (function(oldCons, isDebugEnabled) {
    return {
      log: function(...args) {
        isDebugEnabled && oldCons.log.apply(null, args);
      },
      info: function(...args) {
        isDebugEnabled && oldCons.info.apply(null, args);
      },
      warn: function(...args) {
        isDebugEnabled && oldCons.warn.apply(null, args);
      },
      error: function(...args) {
        isDebugEnabled && oldCons.error.apply(null, args);
      },
      group: function(...args) {
        isDebugEnabled && oldCons.group.apply(null, args);
      },
      debug: function(...args) {
        isDebugEnabled && oldCons.debug.apply(null, args);
      },
    };
  })(window.console, window.MILO_CONFIG.ENABLE_DEBUG);
  window.console = newConsole;
};
