import * as R from 'ramda';
import { httpGet, httpPost, httpPut } from 'apiModule/rest';
import adapter from 'dataModule/adapter';
import { getQueriesString, getIdsObject, getQueriesStringWithArray } from 'utilsModule';

/* Login */
const getServerMapping = async ({ content }) =>
  R.pipe(
    adapter.users.getServerMapping.fe2be,
    httpPost('account/getServerMapping'),
    (response) => response.then(adapter.users.getServerMapping.be2fe),
  )(content);

const request2FA = async ({ content }) =>
  R.pipe(
    adapter.users.request2FA.fe2be,
    httpPost('account/request2FA'),
    (response) => response.then(adapter.users.request2FA.be2fe),
  )(content);

const verify2FA = async ({ content }) =>
  R.pipe(
    adapter.users.verify2FA.fe2be,
    httpPost('account/verify2FA'),
    (response) => response.then(adapter.users.verify2FA.be2fe),
  )(content);

const selectProfile = async ({ content }) =>
  R.pipe(
    adapter.users.selectProfile.fe2be,
    httpPost('account/changeLoginRole'),
    (response) => response.then(adapter.users.selectProfile.be2fe),
  )(content);

  const switchProfile = async ({ content }) =>
  R.pipe(
    adapter.users.switchProfile.fe2be,
    httpPost('account/changeRole'),
    (response) => response.then(adapter.users.switchProfile.be2fe),
  )(content);

//forgetPassword
const forgetPassword = async ({ content }) =>
  R.pipe(
    adapter.users.forgetPassword.fe2be,
    httpPost('account/forgetPassword'),
    (response) => response.then(adapter.users.forgetPassword.be2fe),
  )(content);

//passwordValidation
const passwordVerifyOTP = async ({ content }) =>
  R.pipe(
    adapter.users.passwordVerifyOTP.fe2be,
    httpPost('account/passwordVerifyOTP'),
    (response) => response.then(adapter.users.passwordVerifyOTP.be2fe),
  )(content);

//resetPassrod
const resetPassword = async ({ content }) =>
  R.pipe(
    adapter.users.resetPassword.fe2be,
    httpPost('account/resetPassword'),
    (response) => response.then(adapter.users.resetPassword.be2fe),
  )(content);

  //logout
const logoutAccount = async () =>
  R.pipe(
    adapter.users.logoutAccount.fe2be,
    httpPost('account/logout'),
    (response) => response.then(adapter.users.logoutAccount.be2fe),
  )();
  
  //revoke session
const revokeSession = async () =>
  R.pipe(
    adapter.users.revokeSession.fe2be,
    httpPost('account/revokeSession'),
    (response) => response.then(adapter.users.revokeSession.be2fe),
  )();

/* Onboard */
const requestInvitationCodeForMobile = async ({ content }) =>
  R.pipe(
    adapter.users.requestInvitationCodeForMobile.fe2be,
    httpPost('account/requestInvitationCodeForMobile'),
    (response) => response.then(adapter.users.requestInvitationCodeForMobile.be2fe),
  )(content);

const reqOptOutOnboardNotification = async ({ content }) =>
  R.pipe(
    adapter.users.reqOptOutOnboardNotification.fe2be,
    httpPut('edm/edmPreference'),
    (response) => response.then(adapter.users.reqOptOutOnboardNotification.be2fe),
  )(content);

const requestInvitationToken = async ({ content }) =>
  R.pipe(
    adapter.users.requestInvitationToken.fe2be,
    httpPost('account/requestInvitationToken'),
    (response) => response.then(adapter.users.requestInvitationToken.be2fe),
  )(content);

const requestInvitationCode = async ({ content }) =>
  R.pipe(
    adapter.users.requestInvitationCode.fe2be,
    httpPost('account/requestInvitationCode'),
    (response) => response.then(adapter.users.requestInvitationCode.be2fe),
  )(content);

const verifyInvitationCode = async ({ content }) =>
  R.pipe(
    adapter.users.verifyInvitationCode.fe2be,
    httpPost('account/verifyInvitationCode'),
    (response) => response.then(adapter.users.verifyInvitationCode.be2fe),
  )(content);

const registerAuth = async ({ content }) =>
  R.pipe(
    adapter.users.registerAuth.fe2be,
    httpPost('account/registerAuth'),
    (response) => response.then(adapter.users.registerAuth.be2fe),
  )(content);

const request2FAForOnboard = async ({ content }) =>
  R.pipe(
    adapter.users.request2FAForOnboard.fe2be,
    httpPost('account/request2FAForOnboard'),
    (response) => response.then(adapter.users.request2FAForOnboard.be2fe),
  )(content);

const verify2FAForOnboard = async ({ content }) =>
  R.pipe(
    adapter.users.verify2FAForOnboard.fe2be,
    httpPost('account/verify2FAForOnboard'),
    (response) => response.then(adapter.users.verify2FAForOnboard.be2fe),
  )(content);

const updateDemographic = async ({ content }) =>
  R.pipe(
    adapter.users.updateDemographic.fe2be,
    httpPut('account/demographics'),
    (response) => response.then(adapter.users.updateDemographic.be2fe),
  )(content);

/* Users */
const create = async ({ content }) =>
  R.pipe(
    adapter.users.create.fe2be,
    httpPost('account/register'),
    (response) => response.then(adapter.users.create.be2fe),
  )(content);

const createCovid = async ({ content }) =>
  R.pipe(
    adapter.users.createCovid.fe2be,
    httpPost('account/register/covid'),
    (response) => response.then(adapter.users.createCovid.be2fe),
  )(content);

const updatePatient = async ({ params: { ids }, content }) =>
  R.pipe(
    R.juxt([
      R.pipe(
        R.prop('ids'),
        getIdsObject,
      ),
      R.prop('content'),
    ]),
    ([{ patientId }, feContent]) =>
      R.pipe(
        R.assocPath(['patientId'], patientId),
        adapter.users.updatePatient.fe2be,
        httpPut(`patients/${patientId}`),
      )(feContent),
    (response) => response.then(adapter.users.updatePatient.be2fe),
  )({ ids, content });

const updateProvider = async ({ params: { ids }, content }) =>
  R.pipe(
    R.juxt([
      R.pipe(
        R.prop('ids'),
        getIdsObject,
      ),
      R.pipe(
        R.prop('content'),
        adapter.users.updateProvider.fe2be,
      ),
    ]),
    ([{ providerId }, beContent]) => httpPut(`provider/${providerId}`)(beContent),
    (response) => response.then(adapter.users.updateProvider.be2fe),
  )({ ids, content });

  const updateProfile = async ({ params: { ids }, content }) =>
  R.pipe(
    R.juxt([
      R.pipe(
        R.prop('content'),
        adapter.users.updateProfile.fe2be,
      ),
    ]),
    ([beContent]) => httpPut('account/profile')(beContent),
    (response) => response.then(adapter.users.updateProfile.be2fe),
  )({ ids, content });

const retrieveCurrent = async () =>
  R.pipe(
    () => httpGet('account/getCurrentSession'),
    (response) => response.then(adapter.users.retrieveCurrent.be2fe),
  )();

const retrieveMany = async ({ params: { queries } }) =>
  R.pipe(
    getQueriesString,
    (queriesString) => httpGet(`users${queriesString}`),
    (response) => response.then(adapter.users.retrieveMany.be2fe),
  )(queries);

const retrieveProviders = async ({ params: { queries } }) =>
  R.pipe(
    getQueriesString,
    (queriesString) => httpGet(`provider${queriesString}`),
    (response) => response.then(adapter.users.retrieveProviders.be2fe),
  )(queries);

const retrieveProvider = async ({ params: { ids } }) =>
  R.pipe(
    getIdsObject,
    ({ providerId }) => httpGet(`provider/${providerId}`),
    (response) => response.then(adapter.users.retrieveProvider.be2fe),
  )(ids);

  const retrieveProfile = async ({ params: { ids } }) =>
  R.pipe(
    getIdsObject,
    () => httpGet('account/profile'),
    (response) => response.then(adapter.users.retrieveProvider.be2fe),
  )(ids);

const retrieveSortingFilter = async ({ params: { queries } }) => R.pipe(
  getQueriesString,
  queriesString => httpGet(`sortAndFilterSetting${queriesString}`),
  response => response.then(adapter.users.retrieveSortingFilter.be2fe),
)(queries);

const resetSortingFilter = async ({ params: { queries } }) => R.pipe(
  getQueriesString,
  queriesString => httpGet(`sortAndFilterSetting/reset${queriesString}`),
  response => response.then(adapter.users.resetSortingFilter.be2fe),
)(queries);

const retrievePatients = async ({ params: { queries } }) =>
  R.pipe(
    getQueriesString,
    (queriesString) => httpGet(`patients${queriesString}`),
    (response) => response.then(adapter.users.retrievePatients.be2fe),
  )(queries);

const retrievePatient = async ({ params: { ids } }) =>
  R.pipe(
    getIdsObject,
    ({ patientId }) => httpGet(`patients/${patientId}`),
    (response) => response.then(adapter.users.retrievePatient.be2fe),
  )(ids);

const retrievePatientsCovid = async ({ params: { queries } }) =>
  R.pipe(
    getQueriesString,
    (queriesString) => httpGet(`covid/patients${queriesString}`),
    (response) => response.then(adapter.users.retrievePatientsCovid.be2fe),
  )(queries);

const discharge = async ({ content, params: { ids } }) => {
  const url = R.pipe(
    getIdsObject,
    ({ patientCovidId }) => `covid/patients/${patientCovidId}`,
  )(ids);

  return R.pipe(
    adapter.users.discharge.fe2be,
    httpPut(url),
    (response) => response.then(adapter.users.discharge.be2fe),
  )(content);
};

const maintenanceSchedule = async () =>
  R.pipe(
    () => httpGet('maintenance/check'),
    (response) => response.then(adapter.users.maintenanceSchedule.be2fe),
  )();

const getNotificationSettings = async () =>
  R.pipe(
    () => httpGet('my/notificationSettings'),
    (response) => response.then(adapter.users.getNotificationSettings.be2fe),
  )();

const changeNotificationSettings = async ({ content }) =>
  R.pipe(
    adapter.users.changeNotificationSettings.fe2be,
    httpPost('my/notificationSettings'),
    (response) => response.then(adapter.users.changeNotificationSettings.be2fe),
  )(content);

const updateACL = async ({ content }) =>
  R.pipe(
    adapter.users.updateACL.fe2be,
    httpPut('permissionSetting/updateACLPermission'),
    (response) => response.then(adapter.users.updateACL.be2fe),
  )(content);

const retrieveDefaultAcl = async () =>
  R.pipe(
    () => httpGet('permissionSetting/fetchDefaultACLPermission'),
    (response) => response.then(adapter.users.retrieveDefaultAcl.be2fe),
  )();

const resendAccountActivation = async ({ content }) =>
  R.pipe(
    adapter.users.resendAccountActivation.fe2be,
    httpPost('account/resendOnboardEmail'),
    (response) => response.then(adapter.users.resendAccountActivation.be2fe),
  )(content);

const retrieveNotifications = async () =>
  R.pipe(
    () => httpGet('notifications'),
    (response) => response.then(adapter.users.retrieveNotifications.be2fe),
  )();

const inactivateNotifications = async ({ content }) =>
  R.pipe(
    adapter.users.inactivateNotifications.fe2be,
    httpPost('notifications/inactivate'),
    (response) => response.then(adapter.users.inactivateNotifications.be2fe),
  )(content);

const retrievePatientProfiles = async ({ params: { queries } }) => {
  const queriesString = getQueriesStringWithArray(queries);
  const response = await httpGet(`patients/profiles/${queriesString}`);
  return adapter.users.retrievePatientProfiles.be2fe(response);
};

const getPollingInfo = async () =>
  R.pipe(
    () => httpGet('pollingInfo'),
    (response) => response.then(adapter.users.getPollingInfo.be2fe),
  )();

const DM = {
  getServerMapping,
  request2FA,
  verify2FA,
  selectProfile,
  switchProfile,
  requestInvitationCodeForMobile,
  requestInvitationToken,
  requestInvitationCode,
  verifyInvitationCode,
  registerAuth,
  request2FAForOnboard,
  verify2FAForOnboard,
  updateDemographic,
  create,
  updatePatient,
  updateProvider,
  retrieveCurrent,
  retrieveMany,
  retrieveProviders,
  retrieveProvider,
  retrievePatients,
  retrievePatient,
  retrievePatientsCovid,
  discharge,
  forgetPassword,
  resetPassword,
  logoutAccount,
  revokeSession,
  maintenanceSchedule,
  createCovid,
  passwordVerifyOTP,
  getNotificationSettings,
  changeNotificationSettings,
  updateACL,
  retrieveDefaultAcl,
  resendAccountActivation,
  retrieveNotifications,
  inactivateNotifications,
  retrieveSortingFilter,
  resetSortingFilter,
  retrievePatientProfiles,
  reqOptOutOnboardNotification,
  retrieveProfile,
  updateProfile,
  getPollingInfo
};

const ajax = async ({ method, input }) => ({ [method]: await DM[method](input) });

export default { ...DM, ajax };
