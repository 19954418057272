import React, { PureComponent } from 'react';
import { withNamespaces } from 'react-i18next';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Grid, Button, IconButton, Card, CardContent, Typography, Container } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import uuidv4 from 'uuid/v4';
import { withReduxForm, ShowHidePasswordTextField, Phone } from 'utilsModule/form/reduxFormMui';
import { Version } from 'utilsModule/components';
import { Back } from 'utilsModule/components';
import Logo, { logoAuth } from 'appModule/logo';
import PlayVideoIcon from 'appModule/auth/assets/img/Play-Video.svg';
import readArticleIcon from 'appModule/auth/assets/img/Read-Article.svg';
import HeaderComponent from 'appModule/main/layouts/Header';
// import backgroundImage from '../assets/img/Lifestyle-Login-Image-1x.png';
import Image1 from '../assets/img/Image1.png';
import Image2 from '../assets/img/Image2.png';
import Image3 from '../assets/img/Image3.png';
// import OfflineBubble from '../assets/img/Offline-Bubble.svg';

const styles = (theme) => ({
  reverifyPageContainer: {
    height: '100vh',
    width: '100vw',
    margin: 0,
  },
  pageContainer: {
    // height: '100vh',
    width: '100vw',
    margin: 0,
    // background: `url(${backgroundImage}) center/cover no-repeat`,
    backgroundColor: 'white',
  },
  card: {
    backgroundColor: '#fff',
    zIndex: 10,
    position: 'fixed',
    borderRadius: 32,
    width: 473,
    height: 568,
    margin: '0 auto',
    padding: '40px',
    boxShadow: '0px 0px 100px 2px rgba(136, 136, 136, 0.6)',
  },
  policy: {
    marginTop: 24,
    fontFamily: 'Roboto',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '16px',
    letterSpacing: '0.25px',
    textAlign: 'left',
    display: 'inline-block',
    color: '#212121',
  },
  link: {
    color: '#2c7df6',
    textDecoration: 'underline',
    cursor: 'pointer',
    fontFamily: 'Roboto',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '16px',
    letterSpacing: '0.25px',
  },
  right: {
    textAlign: 'right',
    cursor: 'pointer',
    color: '#007AFF',
  },

  newPageContainer: {
    background: 'white',
    '@media  (min-width: 1441px)': {
      '@media  (min-height: 721px)': {
        padding: '104px 40px 24px 40px',
      },
      '@media  (max-height: 720px)': {
        padding: '88px 24px 0px 24px',
      },
    },
    '@media  (max-width: 1440px)': {
      '@media  (min-height: 721px)': {
        padding: '104px 40px 24px 40px',
      },
      '@media  (max-height: 720px)': {
        padding: '88px 24px 0px 24px',
      },
    },
    '@media  (max-width: 1366px)': {
      '@media  (min-height: 721px)': {
        padding: '104px 40px 24px 40px',
      },
      '@media  (max-height: 720px)': {
        padding: '88px 24px 0px 24px',
      },
    },
    '@media  (max-width: 1280px)': {
      '@media  (min-height: 720px)': {
        padding: '88px 24px 0px 24px',
      },
    },
  },
  newSlide: {
    marginBottom: 24,
    borderRadius: 40,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'bottom left 64px',
    '@media  (min-width: 1441px)': {
      '@media  (min-height: 769px)': {
        height: 856,
        backgroundSize: '890px auto',
      },
      '@media  (max-height: 768px)': {
        height: 624,
        backgroundSize: '624px auto',
      },
      '@media  (max-height: 720px)': {
        height: 608,
        backgroundSize: '632px auto',
      },
    },
    '@media  (max-width: 1440px)': {
      '@media  (min-height: 769px)': {
        height: 856,
        backgroundSize: '890px auto',
      },
      '@media  (max-height: 768px)': {
        height: 624,
        backgroundSize: '624px auto',
      },
      '@media  (max-height: 720px)': {
        height: 608,
        backgroundSize: '632px auto',
      },
    },
    '@media  (max-width: 1366px)': {
      '@media  (min-height: 769px)': {
        height: 856,
        backgroundSize: '890px auto',
      },
      '@media  (max-height: 768px)': {
        height: 624,
        backgroundSize: '624px auto',
      },
      '@media  (max-height: 720px)': {
        height: 608,
        backgroundSize: '632px auto',
      },
    },
    '@media  (max-width: 1280px)': {
      '@media  (min-height: 721px)': {
        height: 856,
        backgroundSize: '890px auto',
      },
      '@media  (max-height: 720px)': {
        height: 608,
        backgroundSize: '632px auto',
      },
    },
  },
  newSlideContainer: {},
  newSlideLeft: {
    padding: 64,
    paddingRight: 0,
    width: 639,
    display: 'flex',
    justifyContent: 'flex-start',
    '@media  (min-width: 1441px)': {
      width: 967,
      padding: '64px',
    },
    '@media  (max-width: 1440px)': {
      width: 679,
    },
    '@media  (max-width: 1366px)': {
      width: 753,
    },
    '@media (max-width: 1280px)': {
      '@media  (max-height: 1024px)': {
        width: 719,
      },
      '@media  (max-height: 720px)': {
        width: 719,
      },
    },
  },
  newSlideRight: {
    display: 'flex',
    justifyContent: 'flex-end',
    '@media  (min-width: 1441px)': {
      '@media  (min-height: 801px)': {
        padding: 60,
      },
      '@media  (min-height: 900px)': {
        padding: 80,
      },
      '@media  (max-height: 800px)': {
        padding: 30,
      },
      '@media  (max-height: 720px)': {
        padding: 20,
      },
    },
    '@media  (max-width: 1440px)': {
      '@media  (min-height: 801px)': {
        padding: 60,
      },
      '@media  (min-height: 900px)': {
        padding: 80,
      },
      '@media  (max-height: 800px)': {
        padding: 30,
      },
      '@media  (max-height: 720px)': {
        padding: 20,
      },
    },
    '@media  (max-width: 1366px)': {
      '@media  (min-height: 801px)': {
        padding: 60,
      },
      '@media  (min-height: 900px)': {
        padding: 80,
      },
      '@media  (max-height: 800px)': {
        padding: 30,
      },
      '@media  (max-height: 720px)': {
        padding: 20,
      },
    },
    '@media (max-width: 1280px)': {
      '@media  (min-height: 801px)': {
        padding: 60,
      },
      '@media  (min-height: 900px)': {
        padding: 80,
      },
      '@media  (max-height: 800px)': {
        padding: 30,
      },
      '@media  (max-height: 720px)': {
        padding: 20,
      },
    },
  },
  loginBox: {
    width: '473px',
    height: '568px',
  },
  slideTitle: {
    width: 639,
    fontFamily: 'Lato',
    fontSize: '40px',
    fontStyle: 'normal',
    fontWeight: 900,
    lineHeight: '48px',
  },
  slideDescription: {
    width: 639,
    marginTop: 16,
    fontFamily: 'Lato',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '24px',
    letterSpacing: '0.5px',
  },
  slideVideoButton: {
    boxShadow: '0px 24px 48px 0px rgba(0, 0, 0, 0.20)',
    width: 164,
    marginTop: 24,
    borderRadius: 4,
    backgroundColor: '#000',
    color: '#fff',
    padding: '8px 24px 8px 12px',
    zIndex: 1,
    '&:hover': {
      background: '#000',
      boxShadow: '0px 24px 48px 0px rgba(0, 0, 0, 0.20)',
    },
  },
  slideVideoButtonText: {
    color: '#fff',
    marginLeft: '12px',
    fontFamily: 'Lato',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0.25px',
    textAlign: 'left',
    textUnderlinePosition: 'from-font',
    textDecorationSkipInk: 'none',
  },
  header: {
    height: 64,
  },
});

const layout = {
  header: {
    containerProps: {
      style: {
        justify: 'center',
        padding: 'unset',
        margin: 'unset',
      },
    },
    components: [
      {
        id: uuidv4(),
        component: logoAuth,
        width: 393,
        style: {
          display: 'block',
          // margin: '0 auto',
          marginBottom: '40px',
        },
        containerProps: {
          style: {
            margin: '0',
          },
        },
        colSpans: { xs: 12, style: { padding: '0' } },
      },
    ],
    colSpans: { style: { padding: '0' } },
  },
  body: {
    sections: [
      {
        id: uuidv4(),
        style: {
          marginTop: 0,
        },
        containerProps: {
          overall: {
            spacing: 0,
          },
          body: {
            spacing: 0,
          },
        },
        rows: [
          {
            id: uuidv4(),
            cols: [
              {
                name: 'mobile',
                label: 'Mobile Number',
                component: Phone,
                required: true,
                inject: ({ runtimeProps: { t } }) => ({ label: t('Mobile Number') }),
                colSpans: {
                  style: {
                    padding: 0,
                    paddingTop: 8,
                    paddingBottom: 12,
                    margin: 0,
                    marginBottom: 8,
                  },
                },
                props: {
                  style: {
                    padding: 0,
                    margin: 0,
                    paddingLeft: 8,
                  },
                },
                containerProps: {
                  style: {
                    padding: 0,
                    margin: 0,
                  },
                },
              },
            ],
          },
          {
            id: uuidv4(),
            cols: [
              {
                name: 'encryptPassword',
                label: 'Password',
                component: ShowHidePasswordTextField,
                required: true,
                inject: ({ runtimeProps: { t } }) => ({ label: t('Password') }),
                colSpans: {
                  style: {
                    padding: 0,
                    paddingTop: 8,
                    paddingBottom: 12,
                    margin: 0,
                    marginBottom: 8,
                  },
                },
                containerProps: {
                  style: {
                    padding: 0,
                    margin: 0,
                  },
                },
              },
            ],
          },
          {
            id: uuidv4(),
            cols: [
              {
                name: 'forgetPassword',
                component: ({ forgetPassword, classes, t }) => (
                  <Grid className={classes.right} onClick={forgetPassword}>
                    <Typography variant="caption">{t('Forgot Password?')}</Typography>
                  </Grid>
                ),
                inject: ({ runtimeProps: { forgetPassword, classes, t } }) => ({ forgetPassword, classes, t }),
                colSpans: {
                  style: {
                    padding: 0,
                    margign: 0,
                    position: 'relative',
                    top: -16,
                    right: 16,
                  },
                },
              },
            ],
          },
        ],
      },
    ],
  },
  footer: {
    itemProps: {
      style: {
        marginTop: 40,
      },
    },
    components: [
      {
        id: uuidv4(),
        component: Button,
        type: 'submit',
        children: 'Log In',
        variant: 'contained',
        color: 'primary',
        style: { width: '100%' },
        className: 'milo-btn-submit',
        colSpans: { xs: 12 },
        inject: ({ submitting, runtimeProps: { t } }) => ({ disabled: submitting, children: t('Log In') }),
      },
    ],
  },
};

const validate = ({ mobile: { value }, password }, { runtimeProps: { t } }) => ({
  value: value !== undefined || value ? '' : t('validation:Please enter Mobile No'),
  password: password ? '' : t('validation:Please enter Password'),
});

const config = {
  form: 'login',
  validate,
};
@withNamespaces('auth', { wait: true })
@withRouter
@withStyles(styles, { index: 1 })
@withReduxForm({ layout, config })
class Login extends PureComponent {
  static propTypes = {
    /* container */
    signIn: PropTypes.func.isRequired,
    forgetPassword: PropTypes.func.isRequired,
    /* withStyles */
    classes: PropTypes.object,
    /* createReduxForm */
    Form: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      viewPorts: window.innerWidth,
    };
  }

  updateDimensions = () => {
    this.setState({ viewPorts: window.innerWidth });
  };

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  renderSlide(slideInfo, renderLogin = false, noBottom = false) {
    const { classes, Form, initialValues, runtimeProps, signIn, t, forgetPassword } = this.props;
    const { title, description, backgroundColor, textColor, buttonUrl, buttonText, buttonIcon, imageUrl } = slideInfo;
    const { viewPorts } = this.state;

    const styleWithBottom = noBottom ? { marginBottom: 0 } : {};
    return (
      <Grid
        item
        xs="12"
        style={{
          backgroundColor: backgroundColor,
          backgroundImage: `url(${imageUrl}`,
          // backgroundSize: 'auto calc(100% - 30px)',
          ...styleWithBottom,
        }}
        className={[classes.newSlide]}
      >
        <Grid container className={classes.newSlideContainer}>
          <Grid item xs={6} className={classes.newSlideLeft}>
            <div>
              <Typography className={classes.slideTitle} style={{ color: textColor }}>
                {title}
              </Typography>
              <Typography className={classes.slideDescription} style={{ color: textColor }}>
                {description}
              </Typography>

              <a href={buttonUrl}>
                <Button variant="contained" disableElevation className={classes.slideVideoButton}>
                  <img src={buttonIcon} />
                  <Typography className={classes.slideVideoButtonText}>{buttonText}</Typography>
                </Button>
              </a>
            </div>
          </Grid>
          {renderLogin && (
            <Grid item xs={6} className={classes.newSlideRight}>
              <Container className={classes.card}>
                <Form
                  runtimeProps={{
                    ...runtimeProps,
                    t,
                    forgetPassword,
                    classes,
                  }}
                  initialValues={initialValues}
                  onSubmit={signIn}
                  sections={[]}
                />
                <Typography variant="caption" className={classes.policy}>
                  By clicking the "Log In" button, you accept and agree to be bound by the terms in our{' '}
                  <Link target="_blank" to="/policies/privacy" className={classes.link}>
                    Privacy Policy
                  </Link>{' '}
                  &{' '}
                  <Link target="_blank" to="/policies/tou" className={classes.link}>
                    Terms of Use
                  </Link>
                  .
                </Typography>
              </Container>
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  }

  render() {
    const { classes } = this.props;

    const infoSlide = [
      {
        title: 'Providing Access to Healthcare Around the World',
        description:
          'At HealthBeats®, we are committed to support the delivery of affordable and accessible healthcare to everyone, everywhere, and at all times.',
        backgroundColor: '#00B451',
        textColor: '#FFFFFF',
        buttonUrl: 'https://youtu.be/yWaaaVU-6Eo',
        buttonText: 'Watch video',
        buttonIcon: PlayVideoIcon,
        imageUrl: Image1,
      },
      {
        title: 'HealthBeats® has an Offline Mode!',
        description:
          'With or without internet access, patients can now take their customised scheduled vitals measurements, receive alert notifications and track their health progress. Data is synchronised automatically.',
        backgroundColor: '#FFB600',
        textColor: '#FFF',
        buttonUrl: 'https://www.youtube.com/watch?v=fUE_m1RkEEg',
        buttonText: 'Watch video',
        buttonIcon: PlayVideoIcon,
        imageUrl: Image2,
      },
      {
        title: 'The Patient Journey',
        description:
          'Supporting patients throughout different phases of their healthcare journey, HealthBeats® platform supports a single patient record across patients’ changing needs and their corresponding healthcare organisations and providers.',
        backgroundColor: '#FF8300',
        textColor: '#FFF',
        buttonUrl: 'https://www.healthbeats.co/solution',
        buttonText: 'Find out more',
        buttonIcon: readArticleIcon,
        imageUrl: Image3,
      },
    ];

    const headerStyle = {
      height: 64,
    };

    return (
      <React.Fragment>
        <HeaderComponent
          isOnBoard={true}
          hideComponents={{ isDisclaimer: true }}
          style={{ customContainerStyle: headerStyle }}
        />
        <Grid style={{ backgroundColor: 'white' }}>
          <Grid container className={classes.newPageContainer}>
            {infoSlide.map((e, i) => {
              return this.renderSlide(e, i === 0, i === infoSlide.length - 1);
            })}
          </Grid>
          <Grid container>
            <Version />
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

export default Login;
