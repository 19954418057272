import { PureComponent } from 'react';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import * as R from 'ramda';
import { isNil } from 'lodash';
import { withResource, resourceTypes, gettersOf } from 'dataModule/store/resources';

@withResource([
  // { resourceType: resourceTypes.VERSIONS, method: 'retrieveWebVersion', options: { runOnDidMount: true } },
  { resourceType: resourceTypes.VERSIONS, method: 'retrieveApiVersion', options: { runOnDidMount: true } },
])
@withStyles(theme => ({
  version: {
    margin: '10px 24px',
    '@media  (min-width: 1441px)': {
      margin: '10px 40px'
    },
  },
}))
class Version extends PureComponent {
  render() {
    const { data, classes } = this.props;

    // console.log('%crender Version', 'font-size: 12px; color: #00b3b3', this.props);

    // Sanitize data
    // const webVersion = gettersOf(resourceTypes.VERSIONS).getWebVersion()(data);
    const webVersion = R.pathOr('x.x.x.x', ['MILO_CONFIG', 'VERSION'], window);
    const apiVersion = gettersOf(resourceTypes.VERSIONS).getApiVersion()(data);

    return (
      <Typography variant='caption' className={classes.version}>
        HealthBeats® Copyright &copy; 2018. All Rights Reserved. | Version Number: {webVersion} | Server Version Number: {apiVersion}
      </Typography>
    );
  }
}

export default Version;
